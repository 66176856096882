import React, {useEffect} from "react"
import { useTranslation } from "react-i18next"


import "react-responsive-carousel/lib/styles/carousel.min.css";

import './equipment.component.less';

import equipmentCityair from "../../assets/img/equipment-cityair/img/equipment-cityair.jpg"
import stationWithModules from "../../assets/img/equipment-cityair/img/station-with-modules.jpg"
import stationWithModulesMobile from "../../assets/img/equipment-cityair/img/station-with-modules-mobile.jpg"
import graphEn from "../../assets/img/equipment-cityair/img/graph_en.jpg"
import graphRu from "../../assets/img/equipment-cityair/img/graph_ru.jpg"
import stationPlace from "../../assets/img/equipment-cityair/img/station-place.jpg"
import stationEgor from "../../assets/img/equipment-cityair/img/station-egor.jpg"
import watch from "../../assets/img/equipment-cityair/icon/watch.svg"
import arrow from "../../assets/img/equipment-cityair/icon/arrow.svg"
import arrowDown from "../../assets/img/icon/arrow_down.svg"
import stationDevice from "../../assets/img/equipment-cityair/img/station-device.jpg"
import G1 from "../../assets/img/equipment-cityair/img/G1.png"
import G2 from "../../assets/img/equipment-cityair/img/G2.png"
import G3 from "../../assets/img/equipment-cityair/img/G3.png"

import {Carousel} from "react-responsive-carousel";
import SEO from "../seo";
import {useI18next} from "gatsby-plugin-react-i18next";

const EquipmentComponent = () => {
    const { t, i18n } = useTranslation()
    const { originalPath } = useI18next()

    function openTopMenu(){
        if(document.getElementById('drop_down').classList.contains('drop_down_close')){
            document.getElementById('drop_down').classList.remove('drop_down_close');
            document.getElementById('menu_top_arrow_down').classList.add('img_arrows_left_menu-open');

        } else {
            document.getElementById('drop_down').classList.add('drop_down_close');
            document.getElementById('menu_top_arrow_down').classList.remove('img_arrows_left_menu-open');
        }
    }

   function pageAnimation(event){
        if (document.getElementById('left_selected').classList.contains('left_selected')) {
            document.getElementById('left_selected').classList.remove('left_selected')
        }
        let newPage = event.target.dataset.page;
        let titleName = event.target;
        if (document.body.clientWidth <= 991) {
            let topMenuBlock = document.getElementsByClassName('top_menu_block');
            for (let i = 0; i < topMenuBlock.length; i++){
                if(topMenuBlock[i].classList.contains('color_purple')) {
                    topMenuBlock[i].classList.remove('color_purple');
                    topMenuBlock[i].classList.add('color_gray');
                }
            }

            titleName.parentNode.classList.remove('color_gray');
            titleName.parentNode.classList.add('color_purple');
            document.getElementById('selected_page__name').classList.add('color_purple');
            document.getElementById('selected_page__name').innerHTML = titleName.dataset.title;
            document.getElementById('drop_down').classList.add('drop_down_close');
            document.getElementById('menu_top_arrow_down').classList.remove('img_arrows_left_menu-open');
        }

        let sectionBlock = document.getElementsByClassName('section_block');
        let currentPage;
        for (let i = 0; i < sectionBlock.length; i++) {
            if (sectionBlock[i].classList.contains('section_block-open')) {
                currentPage = sectionBlock[i].id;
                sectionBlock[i].classList.remove('section_block-open');
            }
        }

        setTimeout(function () {
            document.getElementById(currentPage).classList.remove('display_block');
            document.getElementById(currentPage).classList.add('display_none');
            document.getElementById(newPage).classList.remove('display_none');
            document.getElementById(newPage).classList.add('display_block');
            setTimeout(function () {
                document.getElementById(newPage).classList.add('section_block-open');
            }, 50);
        }, 200);
    }

    const setTooltipPosition = () => {
        const elementWithTooltip = document.getElementById('g3-tooltip-element');
        const tooltip = document.querySelector('.g3-tooltip');
        const windowHeight = window.innerHeight;
        const elementTopPosition = elementWithTooltip.getBoundingClientRect().y;
        
        windowHeight / 2 > elementTopPosition ? tooltip.classList.add('g3-tooltip_position-below') : tooltip.classList.add('g3-tooltip_position-above');
    } 

    const hideTooltip = () => {
        document.querySelector('.g3-tooltip').classList.remove('g3-tooltip_position-below', 'g3-tooltip_position-above');
    }

    /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
    return (
        <div>
            <SEO description={t("metaDescriptionEquipment")} lang={i18n.language} title={t("titleEquipment")} link={"https://cityair.ru/"+ i18n.language + originalPath} />
            <script src="https://ajax.googleapis.com/ajax/libs/jquery/2.2.0/jquery.min.js" />
            <div className="wrapper_container_main align_center_ipad wrapper_container_main-flex">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-6 col-fhd-6 col-rt-6 flex_box_col flex_box_align_ipad">
                            <div className="color_purple equipment_main_block__title">{t("equipmentSubtitle")}</div>
                            <div className="color_black equipment_main_block__sub_title ">Станция CityAir</div>
                            <div className="color_gray equipment_main_block__description equipment_main_block__description-en">Основа для создания поста оперативного контроля загрязнения воздуха</div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-6 col-fhd-6 col-rt-6" />
                    </div>
                </div>
                <div className="wrapper_img_equipment">
                    <img alt="" src={equipmentCityair} className="img_equipment_main" />
                </div>
                <img alt="" src={equipmentCityair} className="img_equipment_main_mobile" />
            </div>

            <div className="wrapper_container wrapper_container-gray color_black_light">
                <div className="container equipment_block_1__container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12 equipment_block_1__string-title color_black">
                            <div>Преимущества</div>
                            <div>станций CityAir</div>
                        </div>
                    </div>
                    <div className="row color_black_light">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5">
                            <div className="equipment_block_1__string-sub_title color_purple">{t("compactSize")}</div>
                            <div className="equipment_block_1__string-description equipment_block_1__string-description_1">{t("anyVerticalSurface")}</div>
                            <div className="equipment_block_1__string-sub_title color_purple">{t("workInAWide")}</div>
                            <div className="equipment_block_1__string-description equipment_block_1__string-description_2">Станции обеспечивают высокое качество измерений при температуре от&nbsp;-40°С до&nbsp;+50°С и&nbsp;в любых метеоусловиях: во время осадков, тумана, сильного ветра&nbsp;и&nbsp;т.д.</div>
                        </div>
                        <div className="col-0 col-sm-0 col-md-0 col-lg-1 col-xl-1 col-xga-1 col-fhd-1 col-rt-1"/>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5">
                            <div className="equipment_block_1__string-sub_title color_purple">{t("builtInBattery")}</div>
                            <div className="equipment_block_1__string-description equipment_block_1__string-description_1">{t("theStationCanOperate")}</div>
                            <div className="equipment_block_1__string-sub_title color_purple">{t("operationAndTransmission")}</div>
                            <div className="equipment_block_1__string-description">Каждую минуту станции автоматически отправляют на сервер результаты измерений</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="wrapper_container">
                <div className="equipment_block_2__container">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5">
                                <div className="equipment_block_2__title">{t("cityAirMonitoringPost")}</div>
                                <div className="equipment_block_2__sub_title">{t("theModularDesignAllows")}</div>
                                <div className="equipment_block_2__description color_gray">{t("cityAirHubAllowsTo")}</div>
                            </div>
                            <div className="col-0 col-sm-0 col-md-0 col-lg-7 col-xl-7 col-xga-7 col-fhd-7 col-rt-7"/>
                        </div>
                    </div>
                    <div className="img_block img_block-right img_block__equipment_with_modules">
                        <img alt="Пост мониторинга CityAir" src={stationWithModules} className="img_equipment_with_modules" />
                    </div>
                    <img alt="Пост мониторинга CityAir" src={stationWithModulesMobile} className="img_equipment_with_modules-mobile" />
                </div>
            </div>

            <div className="wrapper_container wrapper_container-gray">
                <div className="container equipment_block_3__container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5">
                            <div className="equipment_block_3__title equipment_block_3__title-en">{t("cityAirMonitoringPostMeasurements")}</div>
                            <div className="equipment_block_3__description color_black_light">Посты экологического контроля предназначены для измерения содержания в&nbsp;атмосферном воздухе типичных для&nbsp;городов загрязнителей (взвешенные частицы и&nbsp;характерные газы), а&nbsp;также для&nbsp;определения основных метеорологических параметров</div>
                            <div className="equipment_block_3__extra_container">
                                <div className="platform_block__info-important">
                                    <div className="platform_block__info-important__item">Оборудование является утвержденным типом средств измерения. Номер в&nbsp;госреестре <a target="_blank" href="/docs/certificate_CityAir_Dust.pdf" className="platform_block__info-important__link">75984-19</a>, <a target="_blank" href="/docs/Type_approval_certificate_G_module.pdf" className="platform_block__info-important__link">78092-20</a>, <a target="_blank" href="https://fgis.gost.ru/fundmetrology/registry/4/items/1407225" className="platform_block__info-important__link">88474-23</a></div>
                                    <div className="platform_block__info-important__item">Произведено в Российской Федерации</div>
                                </div>
                                <a className="equipment_block__btn" download href="/docs/Datasheet_CityAir.pdf">
                                    <div className="btn_blue">
                                        <div className="btn_blue__arrows" />
                                        <div className="btn_blue__shadows" />
                                    </div>
                                    <div className="btn_blue__note color_purple">{t("downloadDetailedSpecifications")}</div>
                                </a>
                            </div>
                        </div>
                        <div className="col-10 col-sm-10 col-md-10 col-lg-1 col-xl-1 col-xga-1 col-fhd-1 col-rt-1" />
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-6 col-fhd-6 col-rt-6">
                            <div className="equipment_block_3__elements">
                                <div className="equipment_block_3__col_right">
                                    <div className="equipment_block_3__col_right-title color_purple">{t("cityAirStation")}</div>
                                    <div className="equipment_block_3__col_right-description">{t("PM25PM10Temperature")}</div>
                                </div>
                                <div className="equipment_block_3__col_right">
                                    <div className="equipment_block_3__col_right-title color_purple">{t("moduleG1")}</div>
                                    <div className="equipment_block_3__col_right-description">CO, NO<sub>2</sub>, O<sub>3</sub></div>
                                </div>
                                <div className="equipment_block_3__col_right">
                                    <div className="equipment_block_3__col_right-title color_purple">{t("moduleG2")}</div>
                                    <div className="equipment_block_3__col_right-description">SO<sub>2</sub>, H<sub>2</sub>S</div>
                                </div>
                                <div className="equipment_block_3__col_right">
                                    <div className="equipment_block_3__col_right-title color_purple">Газоанализатор Модуль G3</div>
                                    <div className="equipment_block_3__col_right-description">
                                        6 <span id="g3-tooltip-element" className="equipment__g3-tooltip__element" onMouseEnter={()=>setTooltipPosition()} onMouseLeave={()=>hideTooltip()}>вариантов</span> исполнения
                                        <div className="g3-tooltip">
                                            <div className="g3-tooltip__header">
                                                <div className="g3-tooltip__header-column g3-tooltip__header-column-1">Испол&shy;нение</div>
                                                <div className="g3-tooltip__header-column g3-tooltip__header-column-2">Определяемый компонент</div>
                                            </div>
                                            <div className="g3-tooltip__row">
                                                <div className="g3-tooltip__row-column g3-tooltip__column-name">G3.A<span className="color_purple">-01</span></div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">NO<sub>2</sub></div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">O<sub>3</sub></div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">CO</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">NO</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">-</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">-</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">-</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">-</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">-</div>
                                            </div>
                                            <div className="g3-tooltip__row">
                                                <div className="g3-tooltip__row-column g3-tooltip__column-name">G3.A<span className="color_purple">-02</span></div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">NO<sub>2</sub></div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">O<sub>3</sub></div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">CO</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">NO</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">H<sub>2</sub>S</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">SO<sub>2</sub></div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">-</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">-</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">-</div>
                                            </div>
                                            <div className="g3-tooltip__row">
                                                <div className="g3-tooltip__row-column g3-tooltip__column-name">G3.A<span className="color_purple">-03</span></div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">NO<sub>2</sub></div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">O<sub>3</sub></div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">CO</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">NO</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">-</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">-</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">NH<sub>3</sub></div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">CH<sub>2</sub>O</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">-</div>
                                            </div>
                                            <div className="g3-tooltip__row">
                                                <div className="g3-tooltip__row-column g3-tooltip__column-name">G3.A<span className="color_purple">-04</span></div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">NO<sub>2</sub></div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">O<sub>3</sub></div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">CO</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">NO</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">-</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">-</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">-</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">CH<sub>2</sub>O</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">CH<sub>4</sub></div>
                                            </div>
                                            <div className="g3-tooltip__row">
                                                <div className="g3-tooltip__row-column g3-tooltip__column-name">G3.A<span className="color_purple">-05</span></div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">NO<sub>2</sub></div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">O<sub>3</sub></div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">CO</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">NO</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">-</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">-</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">NH<sub>3</sub></div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">-</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">CH<sub>4</sub></div>
                                            </div>
                                            <div className="g3-tooltip__row">
                                                <div className="g3-tooltip__row-column g3-tooltip__column-name">G3.A<span className="color_purple">-06</span></div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">NO<sub>2</sub></div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">O<sub>3</sub></div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">CO</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">-</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">-</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">-</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">NH<sub>3</sub></div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">CH<sub>2</sub>O</div>
                                                <div className="g3-tooltip__row-column g3-tooltip__column-unit">CH<sub>4</sub></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="equipment_block_3__col_right">
                                    <div className="equipment_block_3__col_right-title color_purple">{t("moduleWeatherStation")}</div>
                                    <div className="equipment_block_3__col_right-description">{t("temperatureRelativeHumidity")}</div>
                                </div>
                            </div>
                            <div className="equipment_block_3__col_right__note">
                                <div className="equipment_block_3__note color_gray">{t("listOfMeasuredParameters")}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="wrapper_container overflow-hiden">
                <div className="container equipment_block_4__container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-6 col-fhd-6 col-rt-6">
                            <div className="equipment_block_4__title">{t("qualityOfMeasurements")}</div>
                            <div className="equipment_block_4__sub_title">{t("cityAirStationsAreComparable")}</div>
                            <div className="equipment_block_4__description color_gray">{t("modernDevelopmentsInThe")}</div>
                            <div className="equipment_block_4__description color_gray"><br/>{t("theResultsOfRegularParallel")}</div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-6 col-fhd-6 col-rt-6 equipment_block_4__slider-desktop ggg">
                            <div>
                                {i18n.language === 'en' && <img alt="" className="img_graph" src={graphEn} />}
                                {i18n.language === 'ru' && <img alt="" className="img_graph" src={graphRu} />}
                                <div className="img_graph__description color_gray">{t("collocatedExperimentAt")}</div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-6 col-fhd-6 col-rt-6 equipment_block_4__slider-mobile">
                            <div className="container_slider container_slider-3">
                                <Carousel showStatus={false} showArrows={false}  showThumbs={false} autoPlay>
                                    <div>
                                      {i18n.language === 'en' && <img className="container_slider-img" alt="" src={graphEn} />}
                                      {i18n.language === 'ru' && <img className="container_slider-img" alt="" src={graphEn} />}
                                      <p className="legend legend-slider">{t("collocatedExperimentAt")}</p>
                                    </div>
                                    <div>
                                      <img className="container_slider-img" alt="" src={stationPlace} />
                                      <p className="legend legend-slider">{t("cityAirStationAtThe")}</p>
                                    </div>
                                    <div>
                                      <img className="container_slider-img" alt="" src={stationEgor} />
                                      <p className="legend legend-slider">{t("cityAirStation")}</p>
                                    </div>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="wrapper_container">
                <div className="container equipment_block_5__container">
                    <div className="row equipment_block_4__slider-desktop">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-6 col-fhd-6 col-rt-6">
                            <div className="color_gray equipment_block_5__title">{t("cityAirStationAtThe")}</div>
                            <div>
                                <img alt="" src={stationPlace} className="img_equipment_station_place" />
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-6 col-fhd-6 col-rt-6">
                            <div className="color_gray equipment_block_5__title">{t("cityAirStation")}</div>
                            <div>
                                <img alt="" src={stationEgor} className="img_equipment_station_egor" />
                            </div>
                        </div>
                    </div>
                    <div className="row equipment_block_5__row_3">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12 equipment_block__btn">
                            <img alt="" src={watch} className="img_equipment_watch"/>
                            <div className="color_gray equipment_block_5_description">{t("patentApplications")}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper_container wrapper_container-gray">
                <div className="equipment_block_6__menu-desktop left_menu left_menu-equipment left_menu-equipment_en">
                    <div className="left_menu__block">
                        <input id="left_selected" className="left_menu__input left_selected" onClick={(event)=>pageAnimation(event)} data-page="content_block_1" type="radio" name="contact" />
                        <div className="left_menu_section_name">{t("stationConfiguration")}</div>
                        <div className="left_menu__arrow">
                            <img alt="" src={arrow} className="img_arrows_left_menu" />
                        </div>
                    </div>
                    <div className="left_menu__block">
                        <input className="left_menu__input" onClick={(event)=>pageAnimation(event)} data-page="content_block_2" type="radio" name="contact" />
                        <div className="left_menu_section_name">{t("operatingConditions")}</div>
                        <div className="left_menu__arrow">
                            <img alt="" src={arrow} className="img_arrows_left_menu" />
                        </div>
                    </div>
                    <div className="left_menu__block">
                        <input className="left_menu__input" onClick={(event)=>pageAnimation(event)} data-page="content_block_3" type="radio" name="contact" />
                        <div className="left_menu_section_name">{t("dataTransmission")}</div>
                        <div className="left_menu__arrow">
                            <img alt="" src={arrow} className="img_arrows_left_menu" />
                        </div>
                    </div>
                    <div className="left_menu__block">
                        <input className="left_menu__input" onClick={(event)=>pageAnimation(event)} data-page="content_block_4" type="radio" name="contact" />
                        <div className="left_menu_section_name">{t("dimensionsAndPower")}</div>
                        <div className="left_menu__arrow">
                            <img alt="" src={arrow} className="img_arrows_left_menu"/>
                        </div>
                    </div>
                    <div className="left_menu__block">
                        <input className="left_menu__input" onClick={(event)=>pageAnimation(event)} data-page="content_block_5" type="radio" name="contact" />
                        <div className="left_menu_section_name">{t("extensionModules")}</div>
                        <div className="left_menu__arrow">
                            <img alt="" src={arrow} className="img_arrows_left_menu"/>
                        </div>
                    </div>
                </div>

                <div className="container equipment_block_6__container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12 equipment_block_6__title">
                            {t("specifications")}<br/>{t("specifications2")}
                        </div>
                    </div>
                    <div className="equipment_block_6__menu-mobile">
                        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                        <div className="selected_page" onClick={()=>openTopMenu()}>
                            <div className="selected_page__name top_menu_main_name color_purple" id="selected_page__name">{t("stationConfiguration")}</div>
                            <div className="wrapper_img_arrows_left_menu">
                                <img alt="" src={arrowDown} id="menu_top_arrow_down" className="img_arrows_left_menu"/>
                            </div>
                        </div>
                        <div className="drop_down drop_down_close" id="drop_down">
                            <div className="top_menu_block color_gray">
                                <input className="left_menu__input left_selected" onClick={(event)=>pageAnimation(event)} data-page="content_block_1" type="radio" name="contact_2" data-title={t("stationConfiguration")} />
                                <div className="top_menu_section_name">{t("stationConfiguration")}</div>
                            </div>
                            <div className="top_menu_block color_gray">
                                <input className="left_menu__input" onClick={(event)=>pageAnimation(event)} data-page="content_block_2" type="radio" name="contact_2"  data-title={t("operatingConditions")} />
                                <div className="top_menu_section_name">{t("operatingConditions")}</div>
                            </div>
                            <div className="top_menu_block color_gray">
                                <input className="left_menu__input" onClick={(event)=>pageAnimation(event)} data-page="content_block_3" type="radio" name="contact_2"  data-title={t("dataTransmission")} />
                                <div className="top_menu_section_name">{t("dataTransmission")}</div>
                            </div>
                            <div className="top_menu_block color_gray">
                                <input className="left_menu__input" onClick={(event)=>pageAnimation(event)} data-page="content_block_4" type="radio" name="contact_2"  data-title={t("dimensionsAndPower")} />
                                <div className="top_menu_section_name">{t("dimensionsAndPower")}</div>
                            </div>
                            <div className="top_menu_block color_gray">
                                <input className="left_menu__input" onClick={(event)=>pageAnimation(event)} data-page="content_block_5" type="radio" name="contact_2"  data-title={t("extensionModules")} />
                                <div className="top_menu_section_name">{t("extensionModules")}</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xga-3 col-fhd-3 col-rt-3">
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xga-9 col-fhd-9 col-rt-9">
                            <div className="section_block section_block-open display_block equipment_block_6__section" id="content_block_1">
                                <div className="row reverse_mobile">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5">
                                        <div className="equipment_block_6__sub_section_title">{t("stationConfiguration")}</div>
                                        <div className="equipment_block_6__sub_section_text color_black_light"><div className="equipment_block_6__sub_section_num">1.</div><div>{t("GSMAntenna")}</div></div>
                                        <div className="equipment_block_6__sub_section_text color_black_light"><div className="equipment_block_6__sub_section_num">2.</div><div>{t("ductsForAirSampling")}</div></div>
                                        <div className="equipment_block_6__sub_section_text color_black_light"><div className="equipment_block_6__sub_section_num">3.</div><div>{t("mountingSystem")}</div></div>
                                        <div className="equipment_block_6__sub_section_text color_black_light"><div className="equipment_block_6__sub_section_num">4.</div><div>{t("temperatureHumidityAndPressure")}</div></div>
                                        <div className="equipment_block_6__sub_section_text color_black_light"><div className="equipment_block_6__sub_section_num">5.</div><div>{t("powerCable")}</div></div>
                                        <div className="equipment_block_6__sub_section_text color_black_light"><div className="equipment_block_6__sub_section_num">6.</div><div>{t("GPSAntenna")}</div></div>
                                        <div className="equipment_block_6__sub_section_text color_black_light"><div className="equipment_block_6__sub_section_num">7.</div><div>{t("ONOFFButton")}</div></div>
                                        <div className="equipment_block_6__sub_section_text color_black_light"><div className="equipment_block_6__sub_section_num">8.</div><div>{t("ethernetConnector")}</div></div>
                                        <div className="equipment_block_6__sub_section_text color_black_light"><div className="equipment_block_6__sub_section_num">9.</div><div>{t("connectorForTheExtension")}</div></div>
                                        <div className="equipment_block_6__sub_section_text color_black_light"><div className="equipment_block_6__sub_section_num equipment_block_6__sub_section_num-ten">10.</div><div>{t("serviceConnector")}</div></div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xga-7 col-fhd-7 col-rt-7">
                                        <img alt="" src={stationDevice} className="img_equipment_station_device" />
                                    </div>
                                </div>
                            </div>
                            <div className="section_block display_none equipment_block_6__section" id="content_block_2">
                                <div className="equipment_block_6__sub_section_title">{t("operatingConditions")}</div>
                                <div className="row equipment_block_6__sub_string">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 equipment_block_6__sub_string-measure">{t("temperature")}</div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-6 col-fhd-6 col-rt-6 color_gray equipment_block_6__sub_string-measure">{t("fromCToC")}</div>
                                </div>
                                <div className="row equipment_block_6__sub_string">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 equipment_block_6__sub_string-measure">{t("humidity")}</div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-6 col-fhd-6 col-rt-6 color_gray equipment_block_6__sub_string-measure">{t("fromPercToPerc")}</div>
                                </div>
                                <div className="row equipment_block_6__sub_string">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 equipment_block_6__sub_string-measure">{t("environmentalParameters")}</div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-6 col-fhd-6 col-rt-6 color_gray equipment_block_6__sub_string-measure">{t("smogRainSnowFrost")}<br/>{t("smogRainSnowFrost2")}</div>
                                </div>
                                <div className="row equipment_block_6__sub_string">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 equipment_block_6__sub_string-measure">{t("serviceInterval")}</div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-6 col-fhd-6 col-rt-6 color_gray equipment_block_6__sub_string-measure">{t("months12")}</div>
                                </div>
                            </div>

                            <div className="section_block equipment_block_6__section display_none" id="content_block_3">
                                <div className="equipment_block_6__sub_section_title">{t("dataTransmission")}</div>
                                <div className="row equipment_block_6__sub_string">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5 equipment_block_6__sub_string-measure">{t("dataTransmissionChannels")}</div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xga-6 col-fhd-6 col-rt-6 color_gray equipment_block_6__sub_string-measure equipment_block_6__equipment_indent"><div>{t("GSMGPRS")}</div><div>{t("WiFi")}</div><div>{t("ethernet")}</div></div>
                                </div>
                                <div className="row equipment_block_6__sub_string">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5 equipment_block_6__sub_string-measure">{t("dataTransferToServer")}</div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xga-6 col-fhd-6 col-rt-6 color_gray equipment_block_6__sub_string-measure">{t("upToOncePerMinute")}<br/>{t("upToOncePerMinute2")}</div>
                                </div>
                                <div className="row equipment_block_6__sub_string">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5 equipment_block_6__sub_string-measure">{t("nonVolatileStorageCapacity")}</div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xga-6 col-fhd-6 col-rt-6 color_gray equipment_block_6__sub_string-measure">{t("records40000")}</div>
                                </div>
                                <div className="row equipment_block_6__sub_string">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5 equipment_block_6__sub_string-measure">{t("remoteManagement")}</div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xga-6 col-fhd-6 col-rt-6 color_gray equipment_block_6__sub_string-measure">{t("viaWebInterface")}</div>
                                </div>
                                <div className="row equipment_block_6__sub_string">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5 equipment_block_6__sub_string-measure">{t("selfCheck")}</div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xga-6 col-fhd-6 col-rt-6 color_gray equipment_block_6__sub_string-measure">{t("automatic")}</div>
                                </div>
                            </div>

                            <div className="section_block equipment_block_6__section display_none" id="content_block_4">
                                <div className="equipment_block_6__sub_section_title">{t("dimensionsAndPower")}</div>
                                <div className="row equipment_block_6__sub_string">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5 equipment_block_6__sub_string-measure">{t("dimensions")}</div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xga-7 col-fhd-7 col-rt-7 color_gray equipment_block_6__sub_string-measure">{t("mm")}</div>
                                </div>
                                <div className="row equipment_block_6__sub_string">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5 equipment_block_6__sub_string-measure">{t("weight")}</div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xga-7 col-fhd-7 col-rt-7 color_gray equipment_block_6__sub_string-measure">{t("kg71kg")}</div>
                                </div>
                                <div className="row equipment_block_6__sub_string">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5 equipment_block_6__sub_string-measure">{t("stationProtectionDegree")}</div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xga-7 col-fhd-7 col-rt-7 color_gray equipment_block_6__sub_string-measure">{t("IP53")}</div>
                                </div>
                                <div className="row equipment_block_6__sub_string">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5 equipment_block_6__sub_string-measure">{t("powerNetworkCharacteristics")}</div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xga-7 col-fhd-7 col-rt-7 color_gray equipment_block_6__sub_string-measure">{t("volt110230VHz")}</div>
                                </div>
                                <div className="row equipment_block_6__sub_string">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5 equipment_block_6__sub_string-measure">{t("resistanceToVoltageDrops")}</div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xga-7 col-fhd-7 col-rt-7 color_gray equipment_block_6__sub_string-measure">{t("fromToV")}</div>
                                </div>
                                <div className="row equipment_block_6__sub_string">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5 equipment_block_6__sub_string-measure">{t("autonomousFullyFunctionalOperation")}</div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xga-7 col-fhd-7 col-rt-7 color_gray equipment_block_6__sub_string-measure">{t("atLeast24Hrs")}</div>
                                </div>
                                <br />
                                    <div className="color_gray equipment_block_6__note">{t("vandalProofGrilleManufacturing")}</div>
                            </div>

                            <div className="section_block equipment_block_6__section display_none" id="content_block_5">
                                <div className="row equipment_sub_string">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5 ">
                                        <div className="equipment_block_6__sub_section_title">{t("extensionModules")}</div>
                                        <div className="equipment_block_6__sub_section_description">{t("theStationCanBe")}<br/><br/>{t("theStationCanBe2")}</div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xga-7 col-fhd-7 col-rt-7">
                                        <div className="container_slider">
                                              <Carousel showStatus={false} showArrows={false}  showThumbs={false} autoPlay>
                                                <div>
                                                  <img alt="" src={G1} />
                                                  <p className="legend legend-slider">{t("moduleG1")}</p>
                                                </div>
                                                <div>
                                                  <img alt="" src={G2} />
                                                  <p className="legend legend-slider">{t("moduleG2")}</p>
                                                </div>
                                                <div>
                                                  <img className="module_g3_img" alt="" src={G3} />
                                                  <p className="legend legend-slider">Модуль G3.A</p>
                                                </div>
                                              </Carousel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="equipment_block_6__link">
                        <a className="equipment_block__btn" download href="/docs/Datasheet_CityAir.pdf">
                            <div className="btn_blue">
                                <div className="btn_blue__arrows" />
                                <div className="btn_blue__shadows" />
                            </div>
                            <div className="btn_blue__note color_purple">Скачать подробные характеристики</div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

EquipmentComponent.propTypes = {}

EquipmentComponent.defaultProps = {}

export default EquipmentComponent
